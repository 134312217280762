import { ActionTree } from 'vuex';
import { api } from 'boot/axios';
import { CategoryInterface } from 'src/interfaces/CategoryInterface';
import { AxiosResponse } from 'axios';
import { StateInterface } from '../index';
import { CategoryStateInterface } from '.';

const actions: ActionTree<CategoryStateInterface, StateInterface> = {

  async fetchCategories(context) {
    return api
      .get('/categories')
      .then((response: AxiosResponse<CategoryInterface[]>) => {
        context.commit('SET_CATEGORIES', response.data);
      });
  },
};

export default actions;
