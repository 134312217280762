import { BoardServiceInterface } from 'src/interfaces/BoardServiceInterface';
import { MutationTree } from 'vuex';
import { BoardServicesStateInterface } from '.';

const mutation: MutationTree<BoardServicesStateInterface> = {

  SET_BOARD_SERVICES(state: BoardServicesStateInterface, boardServices: BoardServiceInterface[]) {
    state.boardServices = boardServices;
  },

};

export default mutation;
