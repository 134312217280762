import { ActionTree } from 'vuex';
import { AxiosResponse } from 'axios';
import { AmenityInterface } from 'src/interfaces/AmenityInterface';
import { api } from '../../boot/axios';

import { StateInterface } from '../index';
import { AmenitiesStateInterface } from '.';

const actions: ActionTree<AmenitiesStateInterface, StateInterface> = {
  async fetchAmenities(context, pmsPropertyId: number) {
    const params = `?pmsPropertyId=${pmsPropertyId}`;

    return api
      .get(`/amenities${params}`)
      .then((response: AxiosResponse<AmenityInterface[]>) => {
        context.commit('SET_AMENITIES', response.data);
      });
  },
};

export default actions;
