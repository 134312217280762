import { Module } from 'vuex';
import { UsersInterface } from 'src/interfaces/UsersInterface';
import { StateInterface } from '../index';
import state from './state';
import actions from './actions';
import mutations from './mutations';

export interface UsersStateInterface {
  users: UsersInterface[];
}

const usersModule: Module<UsersStateInterface, StateInterface> = {
  namespaced: true,
  actions,
  mutations,
  state,
};

export default usersModule;
