import { ActionTree } from 'vuex';
import { AxiosResponse } from 'axios';
import { BoardServiceInterface } from 'src/interfaces/BoardServiceInterface';
import { api } from '../../boot/axios';

import { StateInterface } from '../index';
import { BoardServicesStateInterface } from '.';

const actions: ActionTree<BoardServicesStateInterface, StateInterface> = {
  async fetchBoardServices(context, pmsPropertyId: number) {
    return api
      .get(`/board-services?pmsPropertyId=${pmsPropertyId}`)
      .then((response: AxiosResponse<BoardServiceInterface[]>) => {
        context.commit('SET_BOARD_SERVICES', response.data);
      });
  },
};

export default actions;
