import { SaleChannelInterface } from 'src/interfaces/SaleChannelnterface';
import { MutationTree } from 'vuex';
import { SaleChannelsStateInterface } from '.';

const mutation: MutationTree<SaleChannelsStateInterface> = {

  SET_SALE_CHANNELS(state: SaleChannelsStateInterface, saleChannels: SaleChannelInterface[]) {
    state.saleChannels = saleChannels;
  },

};

export default mutation;
