import { TransactionMethodInterface } from 'src/interfaces/TransactionMethodInterface';
import { MutationTree } from 'vuex';
import { AccountJournalsStateInterface } from '.';

const mutation: MutationTree<AccountJournalsStateInterface> = {

  SET_ACCOUNT_JOURNALS(
    state: AccountJournalsStateInterface, payload: TransactionMethodInterface[],
  ) {
    state.accountJournals = payload;
  },

};

export default mutation;
