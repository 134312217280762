import { CountryStatesInterface } from 'src/interfaces/CountryStatesInterface';

import { MutationTree } from 'vuex';
import { CountryStatesStateInterface } from '.';

const mutation: MutationTree<CountryStatesStateInterface> = {

  SET_COUNTRY_STATES(state: CountryStatesStateInterface, countryStates:CountryStatesInterface[]) {
    state.countryStates = countryStates;
  },

  REMOVE_COUNTRY_STATES(state:CountryStatesStateInterface) {
    state.countryStates = [];
  },
};

export default mutation;
