import { ActionTree } from 'vuex';
import { api } from 'boot/axios';
import { StateInterface } from '../index';
import { PaymentTermStateInterface } from '.';

const actions: ActionTree<PaymentTermStateInterface, StateInterface> = {
  async fetchPaymentTerms(context) {
    return api
      .get('/payment-terms')
      .then((response) => {
        context.commit('SET_TRANSACTION_TERMS', response.data);
      });
  },
};

export default actions;
