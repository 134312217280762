import { RoomClosureReasonInterface } from 'src/interfaces/RoomClosureReasonInterface';
import { MutationTree } from 'vuex';
import { RoomClosureReasonsStateInterface } from '.';

const mutation: MutationTree<RoomClosureReasonsStateInterface> = {

  SET_ROOM_CLOSURE_REASONS(state: RoomClosureReasonsStateInterface,
    roomClosureReasons: RoomClosureReasonInterface[]) {
    state.roomClosureReasons = roomClosureReasons;
  },
};

export default mutation;
