import { ActionTree } from 'vuex';
import { api } from 'boot/axios';
import { CountryInterface } from 'src/interfaces/CountryInterface';
import { AxiosResponse } from 'axios';
import { StateInterface } from '../index';
import { CountryStateInterface } from '.';

const actions: ActionTree<CountryStateInterface, StateInterface> = {

  async fetchCountries(context) {
    return api
      .get('/countries')
      .then((response: AxiosResponse<CountryInterface[]>) => {
        context.commit('SET_COUNTRIES', response.data);
      });
  },
};

export default actions;
