import { ActionTree } from 'vuex';
import { api } from 'boot/axios';
import { AxiosResponse } from 'axios';
import { TransactionMethodInterface } from 'src/interfaces/TransactionMethodInterface';
import { StateInterface } from '../index';
import { AccountJournalsStateInterface } from '.';

const actions: ActionTree<AccountJournalsStateInterface, StateInterface> = {

  async fetchAccountJournals(context, pmsPropertyId: number) {
    const params = `?pmsPropertyId=${pmsPropertyId}`;
    return api
      .get(`/account-journals${params}`)
      .then((response: AxiosResponse<TransactionMethodInterface[]>) => {
        context.commit('SET_ACCOUNT_JOURNALS', response.data);
      });
  },

};

export default actions;
