import { Module } from 'vuex';
import { BoardServiceInterface } from 'src/interfaces/BoardServiceInterface';
import { StateInterface } from '../index';
import state from './state';
import actions from './actions';
import mutations from './mutations';

export interface BoardServicesStateInterface {
  boardServices: BoardServiceInterface[];
}

const boardServicesModule: Module<BoardServicesStateInterface, StateInterface> = {
  namespaced: true,
  actions,
  mutations,
  state,
};

export default boardServicesModule;
