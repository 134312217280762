import { AxiosResponse } from 'axios';
import { api } from 'src/boot/axios';
import { InvoiceResultInterface } from 'src/interfaces/InvoiceResultInterface';
import { PayloadInvoiceRequestInterface } from 'src/interfaces/PayloadInvoiceRequestInterface';
import { ActionTree } from 'vuex';
import { InvoicesStateInterface } from '.';
import { StateInterface } from '..';

const actions: ActionTree<InvoicesStateInterface, StateInterface> = {
  async fetchInvoices(context, payload: PayloadInvoiceRequestInterface) {
    let params = '';
    let to = '';
    let from = '';
    if (payload.pmsPropertyId) {
      params += `?pmsPropertyId=${payload.pmsPropertyId}`;
    }
    if (payload.limit) {
      params += `&limit=${payload.limit}`;
    }
    if (payload.offset) {
      params += `&offset=${payload.offset}`;
    }
    if (payload.orderBy) {
      params += `&orderBy=${payload.orderBy}`;
    }
    if (payload.filter) {
      params += `&filter=${payload.filter}`;
    }
    if (payload.paymentState) {
      params += `&paymentState=${payload.paymentState}`;
    }
    if (payload.dateStart && payload.dateEnd) {
      from = `${payload.dateStart.getFullYear()}-${(payload.dateStart.getMonth() + 1).toString().padStart(2, '0')}-${payload.dateStart.getDate().toString().padStart(2, '0')}`;
      to = `${payload.dateEnd.getFullYear()}-${(payload.dateEnd.getMonth() + 1).toString().padStart(2, '0')}-${payload.dateEnd.getDate().toString().padStart(2, '0')}`;
      params += `&dateStart=${from}&dateEnd=${to}`;
    }
    if (payload.originAgencyId) {
      params += `&originAgencyId=${payload.originAgencyId}`;
    }
    return api
      .get(`/invoices${params}`)
      .then((response:AxiosResponse<InvoiceResultInterface>) => {
        if (response) {
          context.commit('SET_INVOICES', response.data.invoices);
          context.commit('SET_TOTAL_AMOUNT', response.data.total);
          context.commit('SET_TOTAL_INVOICES', response.data.totalInvoices);
        }
      });
  },
};

export default actions;
