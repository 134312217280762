import { Module } from 'vuex';
import { CashRegisterInterface } from 'src/interfaces/CashRegisterInterface';
import { CashRegisterResultInterface } from 'src/interfaces/CashRegisterResultInterface';
import { StateInterface } from '../index';
import state from './state';
import actions from './actions';
import mutations from './mutations';

export interface CashRegisterStateInterface {
  cashRegister: CashRegisterInterface| null;
  cashRegisterResult: CashRegisterResultInterface | null;
}

const cashRegistersModule: Module<CashRegisterStateInterface, StateInterface> = {
  namespaced: true,
  actions,
  mutations,
  state,
};

export default cashRegistersModule;
