import { ActionTree } from 'vuex';

import { api } from 'boot/axios';
import { StateInterface } from '../index';
import { UbicationStateInterface } from '.';

const actions: ActionTree<UbicationStateInterface, StateInterface> = {

  async fetchUbications(context, propertyId: number) {
    return api
      .get(`/ubications?pmsPropertyIds[]=${propertyId.toString()}`)
      .then((response) => {
        context.commit('SET_UBICATIONS', response.data);
      });
  },
};

export default actions;
