import { Module } from 'vuex';
import { PaymentTermInterface } from 'src/interfaces/PaymentTermInterface';
import { StateInterface } from '../index';
import state from './state';
import actions from './actions';
import mutations from './mutations';

export interface PaymentTermStateInterface {
  paymentTerms: PaymentTermInterface[],
}

const paymentTermsModule: Module<PaymentTermStateInterface, StateInterface> = {
  namespaced: true,
  actions,
  mutations,
  state,
};

export default paymentTermsModule;
