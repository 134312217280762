import { NotificationInterface } from 'src/interfaces/NotificationInterface';
import { MutationTree } from 'vuex';
import { NotificationsStateInterface } from '.';

const mutation: MutationTree<NotificationsStateInterface> = {

  ADD_NOTIFICATIONS(state: NotificationsStateInterface, notifications: NotificationInterface[]) {
    state.notifications = state.notifications.concat(notifications).slice(0, 30);
  },
  SET_RESERVATIONS_TO_ASSIGN(state: NotificationsStateInterface, numReservationsToAssign: number) {
    state.numReservationsToAssign = numReservationsToAssign;
  },
  READ_NOTIFICATION(state: NotificationsStateInterface, notification: NotificationInterface) {
    const notificationIndex = state.notifications.findIndex(
      (el) => el.folioId === notification.folioId
      && el.timeStamp === notification.timeStamp
      && el.pmsPropertyId === notification.pmsPropertyId,
    );
    if (notificationIndex !== -1) {
      state.notifications.splice(notificationIndex, 1);
    }
  },
};

export default mutation;
