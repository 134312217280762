import { ActionTree } from 'vuex';
import { AxiosResponse } from 'axios';
import { ServiceInterface } from 'src/interfaces/ServiceInterface';
import { ReservationInterface } from 'src/interfaces/ReservationInterface';
import { api } from '../../boot/axios';

import { StateInterface } from '../index';
import { ReservationServicesStateInterface } from '.';

const actions: ActionTree<ReservationServicesStateInterface, StateInterface> = {
  async fetchReservationServices(context, reservationId: number) {
    return api
      .get(`/reservations/${reservationId}/services`)
      .then((response: AxiosResponse<ServiceInterface[]>) => {
        context.commit('SET_RESERVATION_SERVICES', response.data);
      });
  },
  async fetchCurrentFolioReservationServices(context) {
    context.commit('CLEAR_RESERVATION_SERVICES');
    await context.rootState.reservations.reservations?.reduce(
      async (acc, res: ReservationInterface) => {
        await acc;
        await api
          .get(`/reservations/${res.id}/services`)
          .then((response: AxiosResponse<ServiceInterface[]>) => {
            context.commit('ADD_RESERVATION_SERVICES', response.data);
          });
      }, undefined as unknown,
    );
  },
};

export default actions;
