import { ActionTree } from 'vuex';
import { api } from 'boot/axios';
import { PropertyInterface } from 'src/interfaces/PropertyInterface';
import { AxiosResponse } from 'axios';
import { Cookies } from 'quasar';
import { PayloadTransactionReport } from 'src/interfaces/PayloadTransactionReport';
import { StateInterface } from '../index';
import { PropertyStateInterface } from '.';

const actions: ActionTree<PropertyStateInterface, StateInterface> = {

  async fetchProperties(context) {
    return api
      .get('/properties')
      .then((response: AxiosResponse<PropertyInterface[]>) => {
        context.commit('SET_PROPERTIES', response.data);
      });
  },

  setActiveProperty(context, propertyId: number) {
    context.commit('SET_ACTIVE_PROPERTY', context.state.properties.find((el) => el.id === propertyId));
  },

  async travellerReport(context, payload: PayloadTransactionReport) {
    let params = '';
    let to = '';
    let from = '';
    if (payload.pmsPropertyId) {
      params += `?pmsPropertyId=${payload.pmsPropertyId}`;
    }
    if (payload.dateFrom && payload.dateTo) {
      from = `${payload.dateFrom.getFullYear()}-${(payload.dateFrom.getMonth() + 1).toString().padStart(2, '0')}-${payload.dateFrom.getDate().toString().padStart(2, '0')}`;
      to = `${payload.dateTo.getFullYear()}-${(payload.dateTo.getMonth() + 1).toString().padStart(2, '0')}-${payload.dateTo.getDate().toString().padStart(2, '0')}`;
      params += `&dateFrom=${from}&dateTo=${to}`;
    }
    return api
      .get(`properties/traveller-report${params}`);
  },

  reset(context) {
    Cookies.remove('activePropertyId');
    context.commit('CLEAR_ACTIVE_PROPERTY');
  },

};

export default actions;
