import { ActionTree } from 'vuex';
import { api } from 'boot/axios';
import { CountryInterface } from 'src/interfaces/CountryInterface';
import { AxiosResponse } from 'axios';
import { StateInterface } from '../index';
import { CountryStatesStateInterface } from '.';

const actions: ActionTree<CountryStatesStateInterface, StateInterface> = {

  fetchCountryStates(context, payload: number) {
    let result;
    if (payload !== null) {
      result = api
        .get(`/countries/${payload}/country-states`)
        .then((response: AxiosResponse<CountryInterface[]>) => {
          context.commit('SET_COUNTRY_STATES', response.data);
        });
    }
    return result;
  },
  removeCountryStates(context) {
    context.commit('REMOVE_COUNTRY_STATES');
  },
};

export default actions;
