import { Module } from 'vuex';
import { DailyBillingInterface } from 'src/interfaces/DailyBillingInterface';
import { DailyFreeRoomsInterface } from 'src/interfaces/DailyFreeRoomsInterface';
import { AlertsPerDayInterface } from 'src/interfaces/AlertsPerDayInterface';
import { PlanningReservationLineInterface } from 'src/interfaces/PlanningReservationLineInterface';
import { AvailabilityPlanRuleInterface } from 'src/interfaces/AvailabilityPlanRuleInterface';
import { StateInterface } from '../index';
import state from './state';
import actions from './actions';
import mutations from './mutations';

export interface PlanningInterface {
  roomId: number;
  capacity: number;
  roomTypeClassId: number
  roomTypeId: number;
  dates: {
    date: Date;
    reservationLines: PlanningReservationLineInterface[];
    restriction: AvailabilityPlanRuleInterface | null;
  }[];
}
export interface PlanningPricesRulesInterface {
  roomTypeId: number;
  dates: {
    date: Date;
    freeRooms: number;
    pricelistItemId: number;
    availabilityPlanRuleId: number;
    price: number;
    maxAvail: number;
    quota: number;
    closed: boolean;
    closedArrival: boolean;
    closedDeparture: boolean;
    minStay: number;
    maxStay: number;
    minStayArrival: number;
    maxStayArrival: number;
  }[];
}

export interface PlanningHeaderInterface {
  date: Date;
  freeRooms: number;
  occupancyRate: number;
  dailyBilling: number;
  overbooking: boolean;
}

export interface PlanningStateInterface {
  reservationLines: PlanningReservationLineInterface[];
  dailyBillings: DailyBillingInterface[];
  dailyFreeRooms: DailyFreeRoomsInterface[];
  alertsPerDay: AlertsPerDayInterface[]
  dateStart: Date;
  dateEnd: Date;
  planning: PlanningInterface[];
  planningHeaders: PlanningHeaderInterface[];
  planningPricesRules: PlanningPricesRulesInterface[];
  filteredRoomIds: number[];
  movePlanningToSelectedReservation: boolean;
}

const planningModule: Module<PlanningStateInterface, StateInterface> = {
  namespaced: true,
  actions,
  mutations,
  state,
};

export default planningModule;
