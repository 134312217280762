import { Module } from 'vuex';
import { UbicationInterface } from 'src/interfaces/UbicationInterface';
import { StateInterface } from '../index';
import state from './state';
import actions from './actions';
import mutations from './mutations';

export interface UbicationStateInterface {
  ubications: UbicationInterface[];
}

const ubicationModule: Module<UbicationStateInterface, StateInterface> = {
  namespaced: true,
  actions,
  mutations,
  state,
};

export default ubicationModule;
