import { ActionTree } from 'vuex';
import { AxiosResponse } from 'axios';
import { AgencyInterface } from 'src/interfaces/AgencyInterface';
import { api } from '../../boot/axios';

import { StateInterface } from '../index';
import { AgenciesStateInterface } from '.';

const actions: ActionTree<AgenciesStateInterface, StateInterface> = {
  async fetchAgencies(context, pmsPropertyId: number) {
    return api
      .get(`/agencies?pmsPropertyId=${pmsPropertyId}`)
      .then((response: AxiosResponse<AgencyInterface[]>) => {
        context.commit('SET_AGENCIES', response.data);
      });
  },
};

export default actions;
