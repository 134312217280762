import { PricelistStateInterface } from '.';

function state(): PricelistStateInterface {
  return {
    pricelists: [],
    dailyPricelists: [],
    activePricelist: null,
    pricelistItems: [],
  };
}

export default state;
