import { UsersInterface } from 'src/interfaces/UsersInterface';
import { MutationTree } from 'vuex';
import { UsersStateInterface } from '.';

const mutation: MutationTree<UsersStateInterface> = {

  SET_USERS(state: UsersStateInterface, users: UsersInterface[]) {
    state.users = users;
  },

};

export default mutation;
