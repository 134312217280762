import { ActionTree } from 'vuex';
import { api } from 'boot/axios';
import { DocumentTypeInterface } from 'src/interfaces/DocumentTypeInterface';
import { AxiosResponse } from 'axios';
import { StateInterface } from '../index';
import { DocumentTypeStateInterface } from '.';

const actions: ActionTree<DocumentTypeStateInterface, StateInterface> = {

  async fetchDocumentTypes(context) {
    return api
      .get('/id-categories')
      .then((response: AxiosResponse<DocumentTypeInterface[]>) => {
        context.commit('SET_DOCUMENT_TYPES', response.data);
      });
  },
};

export default actions;
