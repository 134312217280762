import { CancelationRuleInterface } from 'src/interfaces/CancelationRuleInterface';
import { MutationTree } from 'vuex';
import { CancelationRulesStateInterface } from '.';

const mutation: MutationTree<CancelationRulesStateInterface> = {

  SET_CANCELATION_RULES(state: CancelationRulesStateInterface,
    cancelationRules: CancelationRuleInterface[]) {
    state.cancelationRules = cancelationRules;
  },

};

export default mutation;
