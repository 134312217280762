import { PriceInterface } from 'src/interfaces/PriceInterface';
import { MutationTree } from 'vuex';
import { PricesStateInterface } from '.';

const mutation: MutationTree<PricesStateInterface> = {

  SET_PRICES(state: PricesStateInterface, prices: PriceInterface[]) {
    prices.forEach((line) => {
      line.date = new Date(line.date);
    });
    state.prices = prices;
  },

};

export default mutation;
