import { boot } from 'quasar/wrappers';
import axios, { AxiosInstance } from 'axios';
import { ApiRestErrorInterface } from 'src/interfaces/ApiRestErrorInterface';
import { Dialog } from 'quasar';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $axios: AxiosInstance;
  }
}

const endPoint = process.env.DEV ? '/api' : 'https://hostaldelcarme.host.roomdoo.com/api';

const api = axios.create({ baseURL: endPoint });

export default boot(({ app, redirect }) => {
  app.config.globalProperties.$axios = axios;
  app.config.globalProperties.$api = api;

  api.interceptors.response.use(
    (response) => response,
    (error: ApiRestErrorInterface) => {
      if (error.response) {
        if (error.response.status === 403) {
          redirect('/login');
        } else {
          Dialog.create({
            title: 'Algo ha ido mal',
            message: error.response.data.description,
            html: true,
          });
        }
      }
    },
  );
});

export { api };
