import { PaymentTermInterface } from 'src/interfaces/PaymentTermInterface';
import { MutationTree } from 'vuex';
import { PaymentTermStateInterface } from '.';

const mutation: MutationTree<PaymentTermStateInterface> = {

  SET_TRANSACTION_TERMS(
    state: PaymentTermStateInterface,
    paymentTerms: PaymentTermInterface[],
  ) {
    state.paymentTerms = paymentTerms;
  },

};

export default mutation;
