import { ActionTree } from 'vuex';
import { AxiosResponse } from 'axios';
import { AmenityTypeInterface } from 'src/interfaces/AmenityTypeInterface';
import { api } from '../../boot/axios';

import { StateInterface } from '../index';
import { AmenityTypesStateInterface } from '.';

const actions: ActionTree<AmenityTypesStateInterface, StateInterface> = {
  async fetchAmenityTypes(context, pmsPropertyId: number) {
    const params = `?pmsPropertyId=${pmsPropertyId}`;

    return api
      .get(`/amenity-types${params}`)
      .then((response: AxiosResponse<AmenityTypeInterface[]>) => {
        context.commit('SET_AMENITY_TYPES', response.data);
      });
  },
};

export default actions;
