import { ActionTree } from 'vuex';
import { api } from 'boot/axios';
import { StateInterface } from '../index';
import { RoomTypeClassStateInterface } from '.';

const actions: ActionTree<RoomTypeClassStateInterface, StateInterface> = {

  async fetchRoomTypeClasses(context, propertyId: number) {
    return api
      .get(`/room-type-classes?pmsPropertyIds[]=${propertyId.toString()}`)
      .then((response) => {
        context.commit('SET_ROOM_TYPE_CLASS', response.data);
      });
  },
};

export default actions;
