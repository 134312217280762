import { ServiceInterface } from 'src/interfaces/ServiceInterface';
import { MutationTree } from 'vuex';
import { FolioServicesStateInterface } from '.';

const mutation: MutationTree<FolioServicesStateInterface> = {

  SET_FOLIO_SERVICES(state: FolioServicesStateInterface,
    services: ServiceInterface[]) {
    state.folioServices = services;
  },

};

export default mutation;
