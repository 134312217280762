import { Module } from 'vuex';
import { SaleChannelInterface } from 'src/interfaces/SaleChannelnterface';
import { StateInterface } from '../index';
import state from './state';
import actions from './actions';
import mutations from './mutations';

export interface SaleChannelsStateInterface {
  saleChannels: SaleChannelInterface[];
}

const saleChannelsModule: Module<SaleChannelsStateInterface, StateInterface> = {
  namespaced: true,
  actions,
  mutations,
  state,
};

export default saleChannelsModule;
