import { CategoryInterface } from 'src/interfaces/CategoryInterface';

import { MutationTree } from 'vuex';
import { CategoryStateInterface } from '.';

const mutation: MutationTree<CategoryStateInterface> = {

  SET_CATEGORIES(state: CategoryStateInterface, categories:CategoryInterface[]) {
    state.categories = categories;
  },
};

export default mutation;
