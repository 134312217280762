import { ServiceInterface } from 'src/interfaces/ServiceInterface';
import { MutationTree } from 'vuex';
import { ReservationServicesStateInterface } from '.';

const mutation: MutationTree<ReservationServicesStateInterface> = {

  SET_RESERVATION_SERVICES(state: ReservationServicesStateInterface,
    services: ServiceInterface[]) {
    state.reservationServices = services;
  },
  CLEAR_RESERVATION_SERVICES(state) {
    state.reservationServices = [];
  },
  ADD_RESERVATION_SERVICES(state: ReservationServicesStateInterface, services: ServiceInterface[]) {
    services.forEach(
      (s) => {
        s.serviceLines.forEach(
          (l) => {
            l.date = new Date(l.date);
          },
        );
        state.reservationServices.push(s);
      },
    );
  },

};

export default mutation;
