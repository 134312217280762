import { AmenityInterface } from 'src/interfaces/AmenityInterface';
import { MutationTree } from 'vuex';
import { AmenitiesStateInterface } from '.';

const mutation: MutationTree<AmenitiesStateInterface> = {

  SET_AMENITIES(state: AmenitiesStateInterface, amenitites: AmenityInterface[]) {
    state.amenities = amenitites;
  },

};

export default mutation;
