import { AgencyInterface } from 'src/interfaces/AgencyInterface';
import { MutationTree } from 'vuex';
import { AgenciesStateInterface } from '.';

const mutation: MutationTree<AgenciesStateInterface> = {

  SET_AGENCIES(state: AgenciesStateInterface, agencies: AgencyInterface[]) {
    state.agencies = agencies;
  },

};

export default mutation;
