import { ActionTree } from 'vuex';
import { api } from 'boot/axios';
import { LanguageInterface } from 'src/interfaces/LanguageInterface';
import { AxiosResponse } from 'axios';
import { StateInterface } from '../index';
import { LanguageStateInterface } from '.';

const actions: ActionTree<LanguageStateInterface, StateInterface> = {

  async fetchLanguages(context) {
    return api
      .get('/languages')
      .then((response: AxiosResponse<LanguageInterface[]>) => {
        context.commit('SET_LANGUAGES', response.data);
      });
  },
};

export default actions;
