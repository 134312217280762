import { ActionTree } from 'vuex';
import { AxiosResponse } from 'axios';
import { ExtraBedInterface } from 'src/interfaces/ExtraBedInterface';
import { ExtraBedsPayloadInterface } from 'src/interfaces/ExtraBedsPayloadInterface';
import { api } from '../../boot/axios';

import { StateInterface } from '../index';
import { ExtraBedsStateInterface } from '.';

const actions: ActionTree<ExtraBedsStateInterface, StateInterface> = {
  async fetchExtraBeds(context, payload: ExtraBedsPayloadInterface) {
    let params = '';
    const from = `${payload.dateFrom.getFullYear()}-${(payload.dateFrom.getMonth() + 1).toString().padStart(2, '0')}-${payload.dateFrom.getDate().toString().padStart(2, '0')}`;
    const to = `${payload.dateTo.getFullYear()}-${(payload.dateTo.getMonth() + 1).toString().padStart(2, '0')}-${payload.dateTo.getDate().toString().padStart(2, '0')}`;
    params = `?dateFrom=${from}&dateTo=${to}&pmsPropertyId=${payload.pmsPropertyId}`;

    return api
      .get(`/extra-beds${params}`)
      .then((response: AxiosResponse<ExtraBedInterface[]>) => {
        context.commit('SET_EXTRA_BEDS', response.data);
      });
  },
};

export default actions;
