import { ActionTree } from 'vuex';
import { AxiosResponse } from 'axios';
import { ServiceInterface } from 'src/interfaces/ServiceInterface';
import { api } from '../../boot/axios';

import { StateInterface } from '../index';
import { FolioServicesStateInterface } from '.';

const actions: ActionTree<FolioServicesStateInterface, StateInterface> = {
  async fetchFolioServices(context, folioId: number) {
    return api
      .get(`/folios/${folioId}/services`)
      .then((response: AxiosResponse<ServiceInterface[]>) => {
        context.commit('SET_FOLIO_SERVICES', response.data);
      });
  },
};

export default actions;
