import { ExtraBedInterface } from 'src/interfaces/ExtraBedInterface';
import { MutationTree } from 'vuex';
import { ExtraBedsStateInterface } from '.';

const mutation: MutationTree<ExtraBedsStateInterface> = {

  SET_EXTRA_BEDS(state: ExtraBedsStateInterface, extraBeds: ExtraBedInterface[]) {
    state.extraBeds = extraBeds;
  },

};

export default mutation;
