import { Module } from 'vuex';
import { ReservationInterface } from 'src/interfaces/ReservationInterface';
import { ReservationWizardStateInterface } from 'src/interfaces/ReservationWizardStateInterface';
import { StateInterface } from '../index';
import state from './state';
import actions from './actions';
import mutations from './mutations';

export interface ReservationStateInterface {
  reservations: ReservationInterface[] | null;
  currentReservation: ReservationInterface | null;
  reservationsForPartnerAsHost: ReservationInterface[];
  reservationsForPartnerAsCustomer: ReservationInterface[];
  reservationsToAssign: ReservationInterface[];
  reservationsWizardState: ReservationWizardStateInterface | null;
}
const reservationsModule: Module<ReservationStateInterface, StateInterface> = {
  namespaced: true,
  actions,
  mutations,
  state,
};

export default reservationsModule;
