import { Module } from 'vuex';
import { ServiceInterface } from 'src/interfaces/ServiceInterface';
import { StateInterface } from '../index';
import state from './state';
import actions from './actions';
import mutations from './mutations';

export interface ServiceStateInterface {
  services: ServiceInterface[];
}

const servicesModule: Module<ServiceStateInterface, StateInterface> = {
  namespaced: true,
  actions,
  mutations,
  state,
};

export default servicesModule;
