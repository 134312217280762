import { ActionTree } from 'vuex';
import { AxiosResponse } from 'axios';
import { AvailabilityInterface } from 'src/interfaces/AvailabilityInterface';
import { api } from '../../boot/axios';

import { StateInterface } from '../index';
import { AvailabilityStateInterface } from '.';

const actions: ActionTree<AvailabilityStateInterface, StateInterface> = {

  async fetchAvailability(context, payload: {
    pmsPropertyId: number;
    from: Date;
    to: Date;
    currentLines: number[] | undefined;
    roomTypeId?: number;
  }) {
    const from = `${payload.from.getFullYear()}-${(payload.from.getMonth() + 1).toString().padStart(2, '0')}-${payload.from.getDate().toString().padStart(2, '0')}`;
    const to = `${payload.to.getFullYear()}-${(payload.to.getMonth() + 1).toString().padStart(2, '0')}-${payload.to.getDate().toString().padStart(2, '0')}`;
    let params = `?availabilityFrom=${from}&availabilityTo=${to}&pmsPropertyId=${payload.pmsPropertyId}`;
    if (payload.currentLines) {
      payload.currentLines.forEach((el) => {
        params += `&currentLines[]=${el}`;
      });
    }
    if (payload.roomTypeId) {
      params += `&roomTypeId=${payload.roomTypeId}`;
    }

    return api
      .get(`/avails${params}`)
      .then((response: AxiosResponse<AvailabilityInterface>) => {
        if (response.data) {
          context.commit('SET_AVAILABILITY', response.data);
        }
      });
  },

};

export default actions;
