import { AmenityTypeInterface } from 'src/interfaces/AmenityTypeInterface';
import { MutationTree } from 'vuex';
import { AmenityTypesStateInterface } from '.';

const mutation: MutationTree<AmenityTypesStateInterface> = {

  SET_AMENITY_TYPES(state: AmenityTypesStateInterface, amenityTypes: AmenityTypeInterface[]) {
    state.amenityTypes = amenityTypes;
  },

};

export default mutation;
