import { ActionTree } from 'vuex';
import { AxiosResponse } from 'axios';
import { UsersInterface } from 'src/interfaces/UsersInterface';
import { api } from '../../boot/axios';

import { StateInterface } from '../index';
import { UsersStateInterface } from '.';

const actions: ActionTree<UsersStateInterface, StateInterface> = {
  async fetchUsers(context, propertyId: number) {
    return api
      .get(`properties/${propertyId}/users`)
      .then((response: AxiosResponse<UsersInterface[]>) => {
        context.commit('SET_USERS', response.data);
      });
  },
};

export default actions;
