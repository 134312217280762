import { ActionTree } from 'vuex';
import { api } from 'boot/axios';
import { PayloadAvailabilityRoomsInterface } from 'src/interfaces/PayloadAvailabilityRooms';
import { StateInterface } from '../index';
import { RoomsStateInterface } from '.';

const actions: ActionTree<RoomsStateInterface, StateInterface> = {

  async fetchRooms(context, propertyId: number) {
    return api
      .get(`/rooms?pmsPropertyId=${propertyId.toString()}`)
      .then((response) => {
        context.commit('SET_ROOMS', response.data);
      });
  },

  async fetchRoomsByAvailability(context, payload: PayloadAvailabilityRoomsInterface) {
    const from = `${payload.availabilityFrom.getFullYear()}-${(payload.availabilityFrom.getMonth() + 1).toString().padStart(2, '0')}-${payload.availabilityFrom.getDate().toString().padStart(2, '0')}`;
    const to = `${payload.availabilityTo.getFullYear()}-${(payload.availabilityTo.getMonth() + 1).toString().padStart(2, '0')}-${payload.availabilityTo.getDate().toString().padStart(2, '0')}`;
    let currentLines = '';
    if (payload.currentLines) {
      payload.currentLines.forEach((el) => {
        currentLines += `&currentLines[]=${el}`;
      });
    }
    let params = `?pmsPropertyId=${payload.pmsPropertyId}&availabilityFrom=${from}&availabilityTo=${to}${currentLines}`;
    if (payload.pricelistId) {
      params += `&pricelistId=${payload.pricelistId}`;
    }

    return api.get(
      `/rooms${params}`,
    );
  },
};

export default actions;
