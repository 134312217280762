import { Module } from 'vuex';
import { TransactionMethodInterface } from 'src/interfaces/TransactionMethodInterface';
import { StateInterface } from '../index';
import state from './state';
import actions from './actions';
import mutations from './mutations';

export interface AccountJournalsStateInterface {
  accountJournals: TransactionMethodInterface[];
}

const accountJournalsModule: Module<AccountJournalsStateInterface, StateInterface> = {
  namespaced: true,
  actions,
  mutations,
  state,
};

export default accountJournalsModule;
