import { RoomInterface } from 'src/interfaces/RoomInterfaces';
import { MutationTree } from 'vuex';
import { RoomsStateInterface } from '.';

const mutation: MutationTree<RoomsStateInterface> = {

  SET_ROOMS(state: RoomsStateInterface, rooms: RoomInterface[]) {
    state.rooms = rooms;
  },
};

export default mutation;
