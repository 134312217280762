import { MutationTree } from 'vuex';
import { CheckinPartnerOcrInterface } from 'src/interfaces/CheckinPartnerOcrInterface';
import { OcrDocumentStateInterface } from '.';

const mutation: MutationTree<OcrDocumentStateInterface> = {

  SET_DOCUMENT_DATA(state: OcrDocumentStateInterface,
    checkinPartnerOcr: CheckinPartnerOcrInterface) {
    if (checkinPartnerOcr.birthdate) {
      const birthdate = checkinPartnerOcr.birthdate.toString().split('/');
      checkinPartnerOcr.birthdate = new Date(`${birthdate[2]}-${birthdate[1]}-${birthdate[0]}`);
      if (!Number(checkinPartnerOcr.birthdate)) {
        checkinPartnerOcr.birthdate = null;
      }
    }
    if (checkinPartnerOcr.documentExpeditionDate) {
      const documentExpeditionDate = checkinPartnerOcr.documentExpeditionDate.toString().split('/');
      checkinPartnerOcr.documentExpeditionDate = new Date(`${documentExpeditionDate[2]}-${documentExpeditionDate[1]}-${documentExpeditionDate[0]}`);
      if (!Number(checkinPartnerOcr.documentExpeditionDate)) {
        checkinPartnerOcr.documentExpeditionDate = null;
      }
    }
    state.documentData = checkinPartnerOcr;
  },

};

export default mutation;
