import { ProductInterface } from 'src/interfaces/ProductInterface';
import { MutationTree } from 'vuex';
import { ProductsStateInterface } from '.';

const mutation: MutationTree<ProductsStateInterface> = {

  SET_PRODUCTS(state: ProductsStateInterface, products: ProductInterface[]) {
    state.products = products;
  },

};

export default mutation;
