import { ActionTree } from 'vuex';
import { AxiosResponse } from 'axios';
import { CancelationRuleInterface } from 'src/interfaces/CancelationRuleInterface';
import { api } from '../../boot/axios';

import { StateInterface } from '../index';
import { CancelationRulesStateInterface } from '.';

const actions: ActionTree<CancelationRulesStateInterface, StateInterface> = {
  async fetchCancelationRules(context, pmsPropertyId: number) {
    return api
      .get(`/cancelation-rules?pmsPropertyId=${pmsPropertyId}`)
      .then((response: AxiosResponse<CancelationRuleInterface[]>) => {
        context.commit('SET_CANCELATION_RULES', response.data);
      });
  },
};

export default actions;
