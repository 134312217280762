import { ActionTree } from 'vuex';

import { api } from 'boot/axios';
import { StateInterface } from '../index';
import { RoomClosureReasonsStateInterface } from '.';

const actions: ActionTree<RoomClosureReasonsStateInterface, StateInterface> = {

  async fetchRoomClosureReasons(context) {
    return api
      .get('/room-closure-reasons')
      .then((response) => {
        context.commit('SET_ROOM_CLOSURE_REASONS', response.data);
      });
  },
};

export default actions;
