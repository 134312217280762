import { DocumentTypeInterface } from 'src/interfaces/DocumentTypeInterface';

import { MutationTree } from 'vuex';
import { DocumentTypeStateInterface } from '.';

const mutation: MutationTree<DocumentTypeStateInterface> = {

  SET_DOCUMENT_TYPES(state: DocumentTypeStateInterface, documentType: DocumentTypeInterface[]) {
    state.documentType = documentType;
  },
};

export default mutation;
