import { InvoiceInterface } from 'src/interfaces/InvoiceInterface';
import { MutationTree } from 'vuex';
import { InvoicesStateInterface } from '.';

const mutation: MutationTree<InvoicesStateInterface> = {
  SET_INVOICES(state: InvoicesStateInterface, partners: InvoiceInterface[]) {
    state.invoices = partners;
  },
  SET_TOTAL_AMOUNT(state: InvoicesStateInterface, total: number) {
    state.total = total;
  },
  SET_TOTAL_INVOICES(state: InvoicesStateInterface, totalInvoices: number) {
    state.totalInvoices = totalInvoices;
  },
};

export default mutation;
