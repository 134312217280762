import { UbicationInterface } from 'src/interfaces/UbicationInterface';
import { MutationTree } from 'vuex';
import { UbicationStateInterface } from '.';

const mutation: MutationTree<UbicationStateInterface> = {

  SET_UBICATIONS(state: UbicationStateInterface, ubications: UbicationInterface[]) {
    state.ubications = ubications;
  },
};

export default mutation;
