import { Module } from 'vuex';
import { AmenityInterface } from 'src/interfaces/AmenityInterface';
import { StateInterface } from '../index';
import state from './state';
import actions from './actions';
import mutations from './mutations';

export interface AmenitiesStateInterface {
  amenities: AmenityInterface[];
}

const amenitiesModule: Module<AmenitiesStateInterface, StateInterface> = {
  namespaced: true,
  actions,
  mutations,
  state,
};

export default amenitiesModule;
