import { AddressStateInterface } from '.';

function state(): AddressStateInterface {
  return {
    address: null,
    addresess: [],
  };
}

export default state;
