import { ActionTree } from 'vuex';

import { api } from 'boot/axios';
import { NotificationInterface } from 'src/interfaces/NotificationInterface';
import { AxiosResponse } from 'axios';
import { StateInterface } from '../index';
import { NotificationsStateInterface } from '.';

const actions: ActionTree<NotificationsStateInterface, StateInterface> = {

  async fetchNotifications(context, date: Date) {
    return api
      .get(`/notifications?fromTimestamp=${date.getTime()}`)
      .then((response: AxiosResponse<NotificationInterface[]>) => {
        response.data.forEach((element) => {
          element.dateTime = new Date(element.timeStamp);
        });
        context.commit('ADD_NOTIFICATIONS', response.data);
      });
  },
  async fetchNotificationsReservationsToAssign(context, pmsPropertyId: number) {
    return api
      .get(`/notifications/reservations-to-assign?pmsPropertyId=${pmsPropertyId}`)
      .then((response: AxiosResponse<{numReservationsToAssign: number}>) => {
        context.commit('SET_RESERVATIONS_TO_ASSIGN', response.data.numReservationsToAssign);
      });
  },
  readNotification(context, notification: NotificationInterface) {
    context.commit('READ_NOTIFICATION', notification);
  },
};

export default actions;
