import { Module } from 'vuex';
import { PartnerInterface } from 'src/interfaces/PartnerInterface';
import { TransactionInterface } from 'src/interfaces/TransactionInterface';
import { InvoiceInterface } from 'src/interfaces/InvoiceInterface';
import { StateInterface } from '../index';
import state from './state';
import actions from './actions';
import mutations from './mutations';

export interface PartnerStateInterface {
  partners: PartnerInterface[];
  totalPartners: number;
  currentPartner: PartnerInterface | null;
  currentPartnerTransactions: TransactionInterface[];
  currentPartnerInvoices: InvoiceInterface[];
}

const partnersModule: Module<PartnerStateInterface, StateInterface> = {
  namespaced: true,
  actions,
  mutations,
  state,
};

export default partnersModule;
