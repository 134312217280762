import { ActionTree } from 'vuex';
import { AxiosResponse } from 'axios';
import { ServiceInterface } from 'src/interfaces/ServiceInterface';
import { ServiceLineInterface } from 'src/interfaces/ServiceLineInterface';
import { PayloadTransactionReport } from 'src/interfaces/PayloadTransactionReport';
import { api } from '../../boot/axios';

import { StateInterface } from '../index';
import { ServiceStateInterface } from '.';

interface PayloadServiceCreation {
  reservationId: number;
  serviceId: number;
  productId: number;
  serviceLines: ServiceLineInterface[]
}

const actions: ActionTree<ServiceStateInterface, StateInterface> = {
  // TODO: Remove this action
  async fetchServices(context, reservationId: number) {
    return api
      .get(`reservations/${reservationId}/services`)
      .then((response: AxiosResponse<ServiceInterface[]>) => {
        context.commit('SET_SERVICES', response.data);
      });
  },

  async deleteService(context, serviceId: number) {
    return api
      .delete(`services/${serviceId}`);
  },

  // TODO: Move this action to reservationServices module
  async createService(context, service: PayloadServiceCreation) {
    return api
      .post(`/reservations/${service.reservationId}/services`, {
        productId: service.productId,
        serviceLines: service.serviceLines.map((el) => ({
          date: `${(el.date as Date).getFullYear()}-${((el.date as Date).getMonth() + 1).toString().padStart(2, '0')}-${(el.date as Date).getDate().toString().padStart(2, '0')}`,
          discount: el.discount,
          priceUnit: el.priceUnit,
          quantity: el.quantity,
        })),
      });
  },

  async updateService(context, service: PayloadServiceCreation) {
    return api
      .patch(`/services/p/${service.serviceId}`, {
        serviceId: service.serviceId,
        serviceLines: service.serviceLines.map((el) => ({
          id: el.id,
          date: `${new Date(el.date as Date).getFullYear()}-${(new Date(el.date as Date).getMonth() + 1).toString().padStart(2, '0')}-${new Date(el.date as Date).getDate().toString().padStart(2, '0')}`,
          discount: el.discount,
          priceUnit: el.priceUnit,
          quantity: el.quantity,
        })),
      });
  },
  async servicesReport(context, payload: PayloadTransactionReport) {
    let params = '';
    let to = '';
    let from = '';
    if (payload.pmsPropertyId) {
      params += `?pmsPropertyId=${payload.pmsPropertyId}`;
    }
    if (payload.dateFrom && payload.dateTo) {
      from = `${payload.dateFrom.getFullYear()}-${(payload.dateFrom.getMonth() + 1).toString().padStart(2, '0')}-${payload.dateFrom.getDate().toString().padStart(2, '0')}`;
      to = `${payload.dateTo.getFullYear()}-${(payload.dateTo.getMonth() + 1).toString().padStart(2, '0')}-${payload.dateTo.getDate().toString().padStart(2, '0')}`;
      params += `&dateFrom=${from}&dateTo=${to}`;
    }
    return api
      .get(`services/services-report${params}`);
  },
};

export default actions;
