import { LayoutStateInterface } from '.';

function state(): LayoutStateInterface {
  return {
    leftDrawerExpanded: false,
    rightDrawerExpanded: false,
    leftDrawerHovered: false,
    showing: 'FolioList',
    pricelistPlanningExpanded: false,
    popUpOpen: '',
    rightDrawerSearchParam: '',
    rightDrawerFilter: '',
    keepModalOpen: false,
    forceMoveFolioTab: '',
  };
}

export default state;
