import { ActionTree } from 'vuex';
import { AxiosResponse } from 'axios';
import { SaleChannelInterface } from 'src/interfaces/SaleChannelnterface';
import { api } from '../../boot/axios';

import { StateInterface } from '../index';
import { SaleChannelsStateInterface } from '.';

const actions: ActionTree<SaleChannelsStateInterface, StateInterface> = {
  async fetchSaleChannels(context, pmsPropertyId: number) {
    const params = `?pmsPropertyId=${pmsPropertyId}`;
    return api
      .get(`/sale-channels${params}`)
      .then((response: AxiosResponse<SaleChannelInterface[]>) => {
        context.commit('SET_SALE_CHANNELS', response.data);
      });
  },
};

export default actions;
