import { ServiceInterface } from 'src/interfaces/ServiceInterface';
import { MutationTree } from 'vuex';
import { ServiceStateInterface } from '.';

const mutation: MutationTree<ServiceStateInterface> = {

  SET_SERVICES(state: ServiceStateInterface, services: ServiceInterface[]) {
    state.services = services;
  },

};

export default mutation;
