import { LanguageInterface } from 'src/interfaces/LanguageInterface';

import { MutationTree } from 'vuex';
import { LanguageStateInterface } from '.';

const mutation: MutationTree<LanguageStateInterface> = {

  SET_LANGUAGES(state: LanguageStateInterface, languages:LanguageInterface[]) {
    state.languages = languages;
  },
};

export default mutation;
