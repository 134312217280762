import { Module } from 'vuex';
import { RoomInterface } from 'src/interfaces/RoomInterfaces';
import { StateInterface } from '../index';
import state from './state';
import actions from './actions';
import mutations from './mutations';

export interface RoomsStateInterface {
  rooms: RoomInterface[];
}

const roomsModule: Module<RoomsStateInterface, StateInterface> = {
  namespaced: true,
  actions,
  mutations,
  state,
};

export default roomsModule;
