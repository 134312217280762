import { RoomTypeClassInterface } from 'src/interfaces/RoomTypeClassInterface';
import { MutationTree } from 'vuex';
import { RoomTypeClassStateInterface } from '.';

const mutation: MutationTree<RoomTypeClassStateInterface> = {

  SET_ROOM_TYPE_CLASS(state: RoomTypeClassStateInterface, roomTypeClass: RoomTypeClassInterface[]) {
    state.roomTypeClasses = roomTypeClass;
  },
};

export default mutation;
