import { Module } from 'vuex';
import { PricelistInterface } from 'src/interfaces/PricelistInterface';
import { PricelistItemInterface } from 'src/interfaces/PricelistItemInterface';
import { StateInterface } from '../index';
import state from './state';
import actions from './actions';
import mutations from './mutations';

export interface PricelistStateInterface {
  pricelists: PricelistInterface[];
  dailyPricelists: PricelistInterface[];
  activePricelist: PricelistInterface | null;
  pricelistItems: PricelistItemInterface[];
}
const pricelistsModule: Module<PricelistStateInterface, StateInterface> = {
  namespaced: true,
  actions,
  mutations,
  state,
};

export default pricelistsModule;
