import { AddressInterface } from 'src/interfaces/AddressInterface';
import { MutationTree } from 'vuex';
import { AddressStateInterface } from '.';

const mutation: MutationTree<AddressStateInterface> = {

  SET_ADDRESS(state: AddressStateInterface, address: AddressInterface) {
    state.address = address;
  },

  SET_ADDRESSES(state: AddressStateInterface, addresses: AddressInterface[]) {
    state.addresess = addresses;
  },

  REMOVE_ADDRESSES(state: AddressStateInterface) {
    state.addresess = [];
  },

  REMOVE_ADDRESS(state: AddressStateInterface) {
    state.address = null;
  },

};

export default mutation;
