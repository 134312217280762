import { Module } from 'vuex';
import { CheckinPartnerOcrInterface } from 'src/interfaces/CheckinPartnerOcrInterface';
import { StateInterface } from '../index';
import state from './state';
import actions from './actions';
import mutations from './mutations';

export interface OcrDocumentStateInterface {
  documentData: CheckinPartnerOcrInterface;
}

const ocrDocumentModule: Module<OcrDocumentStateInterface, StateInterface> = {
  namespaced: true,
  actions,
  mutations,
  state,
};

export default ocrDocumentModule;
