import { CountryInterface } from 'src/interfaces/CountryInterface';

import { MutationTree } from 'vuex';
import { CountryStateInterface } from '.';

const mutation: MutationTree<CountryStateInterface> = {

  SET_COUNTRIES(state: CountryStateInterface, countries:CountryInterface[]) {
    state.countries = countries;
  },
};

export default mutation;
